@import url("https://fonts.googleapis.com/css?family=Inter:300,700,600");

.manage-cash-section {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 140px;
    padding-bottom: 160px;
    align-items: center;
}

.manage-cash-container {
    max-width: 850px;
    padding-top: 60px;
}

.manage-cash-title-col {
    padding-right: 100px;
    padding-left: 100px;
    justify-content: center;
}

.manage-cash-title {
    font-family: "Inter";
    font-weight: 600;
    font-size: 54px;
    color: #383838;
    line-height: 60px;
}

.manage-cash-sub-text {
    font-family: "Inter";
    font-weight: 300;
    font-size: 16px;
    color: #383838;
    margin-top: 40px;
    line-height: 22px;
}

.phone-image {
    width: 330px;
}


@media screen and (max-width: 1024px) {

    .manage-cash-container {
        padding-left: 60px;
        padding-right: 60px;
    }

    .manage-cash-title-col {
        padding-right: 40px;
        padding-left: 40px;
        justify-content: center;
    }

    .manage-cash-title {
        font-size: 48px;
        line-height: 56px;
    }

}

@media screen and (max-width: 960px) {

    .manage-cash-section {
        padding-top: 180px;
    }

}


@media screen and (max-width: 768px) {

    .manage-cash-title-col {
        padding-right: 30px;
        padding-left: 30px;
        justify-content: center;
    }

    .manage-cash-container {
        padding-top: 60px;
    }

    .manage-cash-title {
        font-size: 42px;
        line-height: 52px;
    }

}


@media screen and (max-width: 550px) {

    .manage-cash-section {
        background-color: #1A0253;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 140px;
        padding-bottom: 160px;
    }

    .manage-cash-container {
        padding-top: 0px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .manage-cash-title-col {
        padding-right: 0px;
        padding-left: 0px;
        justify-content: center;
    }

    .manage-cash-title {
        font-weight: 600;
        font-size: 24px;
        color: #F2F2F7;
        line-height: 32px;
    }
    
    .manage-cash-sub-text {
        font-family: "Inter";
        font-weight: 300;
        font-size: 16px;
        color: #F2F2F7;
        margin-top: 40px;
        line-height: 22px;
    }

}