
.legal-doc-row {
    cursor: pointer;
    padding: 0px;
    margin-top: 15px;
}

.legal-doc-link {
    font-size: 16px;
    color: #2A0084;
    text-decoration: none;
}