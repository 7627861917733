
.main-login-section {
    /* make this container take up the full height of the screen */
    height: 100vh;
    background: linear-gradient(180deg, rgb(14.49, 3.87, 40.37) 0%, rgba(52.71, 15.05, 144.5, 0.99) 100%);
}

.main-login-container {
    /* Center the items vertically */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85vh;
}

.login-input-container {
    padding: 70px 50px;
    width: 450px;
}
