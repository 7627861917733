@import url("https://fonts.googleapis.com/css?family=Inter:300,700,600");

.backed-by-section {
    background-color: #F4F3F0;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 160px;
    padding-bottom: 140px;
}

.backed-by-container {
    max-width: 1100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
}

.backed-by-row {
    display: flex;
    flex-direction: row;
}

.backed-by-col {
    flex: 1;
    padding-left: 30px;
    padding-right: 30px;
}

.backed-by-header {
    font-family: "Inter";
    font-weight: 600;
    color: #383838;
    font-size: 24px;
    margin-bottom: 10px;
    line-height: 30px;
}

.backed-by-message {
    font-family: "Inter";
    font-weight: 300;
    color: #383838;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 22px;
}

.backed-by-yc-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.backed-by-text {
    font-family: "Inter";
    font-size: 24px;
    color: #8F8F8F;
    font-weight: 600;
}

.yc-logo {
    height: 70px;
}


@media screen and (max-width: 1024px) {

    .backed-by-container {
        width: 100%;
        max-width: 900px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: auto;
    }

}


@media screen and (max-width: 768px) {

    .backed-by-container {
        width: 97%;
    }

    .backed-by-col {
        padding-left: 20px;
        padding-right: 20px;
    }

}


@media screen and (max-width: 550px) {

    .backed-by-container {
        width: 100%;
    }

    .backed-by-row {
        display: flex;
        flex-direction: column;
    }

    .backed-by-col {
        margin-bottom: 120px;
    }

    .backed-by-yc-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }

    .yc-logo {
        margin-top: 10px;
    }

}