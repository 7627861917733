
.portfolio-allocation-row {
    border-bottom: 1px solid #E8E8E8;
    padding-top: 10px;
    padding-bottom: 5px;
}

.portfolio-allocation-title {
    font-size: 16px;
    margin-bottom: 10px;
}

.portfolio-allocation-percentage {
    font-size: 20px;
    color: #2A0084;
    margin-top: 0px;
}
