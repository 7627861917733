
.light-font {
    font-family: "Inter";
    font-weight: 300;
    font-size: 14px;
    color: rgba(56, 56, 56, 1);
}

.regular-font {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    color: rgba(56, 56, 56, 1);
}

.medium-font {
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    color: rgba(56, 56, 56, 1);
}

.semi-bold-font {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: rgba(56, 56, 56, 1);
}

.bold-font {
    font-family: "Inter";
    font-weight: 700;
    font-size: 14px;
    color: rgba(56, 56, 56, 1);
}