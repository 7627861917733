
.linked-account-card-container {
    padding: 10px 25px;
}

.linked-account-card-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.linked-account-card-logo-col {
    flex: 1;
}

.linked-account-card-bank-logo {
    width: 25px;
}

.linked-account-card-name-col {
    display: flex;
    flex-direction: row;
    flex: 4;
}

.linked-account-card-bank-name {
    margin-right: 5px;
}

.linked-account-card-balance-col {
    flex: 1;
}