
.deposit-page-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;
}

.deposit-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 800px;
    margin: auto;
}

.deposit-header-col {
    flex: 1
}

.deposit-header-main-col {
    /* align the text in the center */
    flex: 5;
    text-align: center;
}

.deposit-withdrawal-edit-button {
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
    color: #2A0084;
}

.deposit-withdrawal-container {
    padding: 20px 30px;
}

.deposit-withdrawal-row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #E8E8E8;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
}

.deposit-withdrawal-title-col {
    flex: 1;
}

.deposit-withdrawal-info-col {
    display: flex;
    flex: 4;
    flex-direction: row;
    align-items: center;
}

.deposit-withdrawal-button-container {
    display: flex;
    margin-top: 85px;
    margin-bottom: 15px;
    width: 140px;
}

.deposit-withdrawal-button-col {
    flex: 1;
}
