
.transfers-page-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.transfers-page-col {
    flex: 6;
    width: 100%;
}

.transfers-page-dummy-col {
    flex: 3;
    width: 100%;
}

.transfers-link-container {
    margin-bottom: 20px;
}

.deposit-col {
    flex: 3;
    padding-right: 10px;
}

.withdrawal-col {
    flex: 3;
    padding-left: 10px;
    flex-direction: column;
}

.deposit-withdrawal-card {
    border-radius: 40px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 35px;
    padding-right: 35px;
    cursor: pointer;
    
    /* align the items at the each end of the container */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.deposit-card {
    background-color: #2A0084;
    border: 3px solid #2A0084;
}

.withdrawal-card {
    border: 3px solid #2A0084;
}

.make-deposit-text {
    color: white;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.make-withdrawal-text {
    color: #2A0084;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
}


/* Smaller laptop screens */
@media only screen and (min-width: 1180px) and (max-width: 1400px) {

    .transfers-page-col {
        flex: 6;
        width: 100%;
    }
    
    .transfers-page-dummy-col {
        flex: 2;
        width: 100%;
    }
    
}


/* iPad landscape styling (this handles the new iPads) */
@media only screen and (min-width: 820px) and (max-width: 1180px) and (orientation: landscape) {

    .transfers-page-col {
        flex: 6;
        width: 100%;
    }
    
    .transfers-page-dummy-col {
        flex: 1;
        width: 100%;
    }
    
}

/* iPad portrait styling (this handles the new iPads) */
@media only screen and (min-width: 768px) and (max-width: 820px) and (orientation: portrait) {

    .transfers-page-col {
        flex: 6;
        width: 100%;
    }
    
    .transfers-page-dummy-col {
        flex: 0;
        width: 100%;
    }
    
}