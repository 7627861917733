
.document-container {
    margin-top: 20px;
}

.document-name {
    font-size: 16px;
}

.document-upload-button-container {
    width: 225px;
    margin-top: 50px;
}