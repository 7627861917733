
body {
    background-color: white;
}

.main-wrapper-container {
    display: flex;
    width: 100%;
}

.main-wrapper-content {
    display: flex;
    flex-direction: column;
    margin-left: 220px;
    width: calc(100% - 80px);
    padding-left: 40px;
    padding-right: 40px;
}
