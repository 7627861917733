
.document-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    overflow: hidden;
}

.document-name-container {
    width: 250px;
}

.document-image-container {
    display: flex;
    width: 150px;
    justify-content: flex-end;
}

.non-uploaded-document {
    cursor: pointer;
}

.document-upload-label {
    cursor: pointer;
}