@import url("https://fonts.googleapis.com/css?family=Inter:300,700,600");

.work-alongside-section {
    background-color: #7D715F;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 140px;
    padding-bottom: 160px;
    align-items: center;
}

.work-alongside-container {
    max-width: 900px;
}

.work-alongside-title-col {
    padding-left: 100px;
    padding-right: 100px;
}

.work-alongside-title {
    font-family: "Inter";
    font-weight: 600;
    font-size: 32px;
    color: white;
    line-height: 40px;
}

.work-alongside-sub-text {
    font-family: "Inter";
    font-weight: 300;
    font-size: 16px;
    color: white;
    margin-top: 40px;
    line-height: 22px;
}


@media screen and (max-width: 1024px) {

    .work-alongside-container {
        display: flex;
        align-items: center;
        max-width: 800px;
        padding-top: 40px;
    }

    .work-alongside-title-col {
        padding-left: 50px;
        padding-right: 50px;
        align-items: center;
    }

}

@media screen and (max-width: 850px) {

    .work-alongside-container {
        display: flex;
        align-items: center;
        padding-top: 40px;
        padding-left: 50px;
        padding-right: 50px;
    }

    .work-alongside-title-col {
        padding-left: 0px;
        padding-right: 0px;
        align-items: center;
    }

}


@media screen and (max-width: 550px) {

    .work-alongside-container {
        display: flex;
        align-items: center;
        padding-top: 40px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .work-alongside-title {
        font-family: "Inter";
        font-weight: 600;
        font-size: 24px;
        color: white;
        line-height: 32px;
    }

    .work-alongside-title-col {
        padding-left: 0px;
        padding-right: 0px;
    }

}