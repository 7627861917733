.header {
    margin-bottom: 30px;
}

.sub-header {
    line-height: 20px;
}

.recommended-portfolio-allocation-section {
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 50px;
}

.recommended-portfolio-allocation-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.symbol-container {
    display: flex;
    justify-content: flex-start;
    flex: 1;
}

.description-container {
    display: flex;
    flex: 5
}

.percentage-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.further-info {
    line-height: 20px;
}

.loading-indicator-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}


/* iPhone styling */
@media (max-width: 550px) {

    .recommended-portfolio-allocation-section {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 50px;
    }
    

}