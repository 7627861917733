
.balance-display-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.small-balance-text {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: #383838;
    margin-top: 3px;
    margin-bottom: 0px;
}

.main-balance-text {
    font-family: "Inter";
    font-weight: 600;
    font-size: 24px;
    color: #383838;
    margin-top: 0px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 0px;
}
