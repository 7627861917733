.section-container {
    margin: auto;
}

.header {
    font-size: 28px;
    margin-bottom: 0px;
}

.sub-header {
    font-size: 14px;
    margin-top: 5px;
}

.legal-name-container {
    margin-top: 80px;
    width: '100%';
}

.get-started-first-name-container {
    flex: 3;
    margin-right: 10px;
}

.get-started-company-name-container {
    flex: 5;
}

.link-text {
    text-decoration: none;
    color: blue;
}

.checkbox-text {
    line-height: 20px;
}