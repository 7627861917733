
.transfer-row {
    border-bottom: 1px solid #E8E8E8;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.transfer-bank-logo-col {
    flex: 1
}

.transfer-bank-logo {
    width: 25px;
}

.transfer-info-col {
    display: flex;
    flex-direction: column;
    flex: 5
}

.transfer-info-to-from-account-container {
    display: flex;
    flex-direction: row;
}

.transfer-amount-col {
    display: flex;
    flex-direction: row;
    flex: 3;
    justify-content: flex-end;
    align-items: center;
}

.transfer-to-from {
    font-size: 16px;
    margin-bottom: 0px;
    margin-right: 6px;
}

.transfer-to-from-account {
    font-size: 16px;
    margin-bottom: 0px;
}

.transfer-date {
    font-size: 16px;
    margin-top: 5px;
    color: #8F8F8F;
}

.transfer-plus-minus {
    font-size: 16px;
    margin-right: 5px;
}
