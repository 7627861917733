@import url("https://fonts.googleapis.com/css?family=Inter:400,300,700,500,600");

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.checkbox-text-section {
    padding-right: 20px;
}

.checkbox-wrapper {
    margin-right: 20px;
}

.checkbox-wrapper:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.checkbox-wrapper label {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.checkbox-header {
    font-size: 16px;
    margin-bottom: 10px;
}

.checkbox-text {
    margin-top: 5px;
}

/* Customizing the checkbox */

.checkbox-wrapper input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #D8D8D8;
    outline: none;
    cursor: pointer;
}

input.checked {
    background-color: #2A0084;
    border: 1px solid #2A0084;
    position: relative;
}

input.checked::before {
    content: "\2713";
    font-size: 20px;
    color: #fff;
    position: absolute;
    right: 2px;
    top: -1px;
}

.checkbox-wrapper input[type="checkbox"]:focus {
    box-shadow: 0 0 20px #2A0084;
}