@import url("https://fonts.googleapis.com/css?family=Inter:300,700,600");

.main-section {
    background: linear-gradient(180deg, rgb(14.49, 3.87, 40.37) 0%, rgba(52.71, 15.05, 144.5, 0.99) 100%);
    border: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 160px;
    overflow: hidden;
}

.home-main-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-right: 20px;
    padding-top: 100px;
}

.home-request-access-section {
    padding-right: 0px;
}

.request-access-input-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
}

.home-first-name-request-access-container {
    margin-right: 5px;
    flex: 3;
}

.home-company-name-request-access-container {
    margin-left: 5px;
    flex: 5;
}

.request-access-button {
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    margin: auto;
    margin-top: 10px;
    background-color: white;
    color: #2A0084;
    padding-top: 15px;
    padding-bottom: 15px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.title-col {
    margin-right: 15%;
    margin-left: 7.5%;
    
}

.main-title {
    font-family: "Inter";
    font-weight: 700;
    font-size: 54px;
    color: #F2F2F7;
    line-height: 64px;
    margin-top: 0px;
    padding-right: 100px;
}

.main-title-sub-text {
    font-family: "Inter";
    font-weight: 300;
    font-size: 16px;
    color: #F2F2F7;
    margin-top: 20px;
    line-height: 22px;
    margin-bottom: 50px;
}

.main-home-image-col {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    padding-bottom: 20px;
}

.dashboard-card-image {
    width: 1150px;
    box-shadow: 0px 10px 10px 5px rgba(0,0,0,0.3);
}

.request-access-success-message {
    font-family: "Inter";
    color: white;
    font-size: 16px;
    font-weight: 600;
}


@media screen and (max-width: 1500px) {

    .home-main-container {
        padding-top: 80px;
    }

    .dashboard-card-image {
        width: 900px;
    }

}


@media screen and (max-width: 1200px) {

    .main-title {
        font-size: 48px;
        line-height: 58px;
    }

    .main-home-image-col {
        margin-right: 0px;
    }

    .title-col {
        margin-right: 10%;
    }

    .dashboard-card-image {
        width: 750px;
        border-radius: 10px;
    }

}


@media screen and (max-width: 960px) {

    .home-main-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-right: 0px;
        padding-top: 140px;
        padding-bottom: 20px;
    }

    .title-col {
        max-width: 475px;
        margin-right: 7.5%;;
    }

    .dashboard-card-image {
        width: 750px;
        border-radius: 10px;
        box-shadow: 0px 8px 8px 2px rgba(0,0,0,0.25);
    }

    .main-home-image-col {
        margin-top: 100px;
        margin-left: 20%;
    }

    .main-section {
        padding-bottom: 0px;
        margin-bottom: -150px;
    }

}


/*@media screen and (max-width: 750px) {

    .home-main-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin: auto;
        padding-right: 20px;
        padding-top: 80px;
        padding-left: 0px;
    }

    .request-access-input-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
    }

    .home-first-name-request-access-container {
        margin-right: 0px;
        margin-bottom: 8px;
    }

    .home-company-name-request-access-container {
        margin-left: 0px;
    }

    .title-col {
        padding-right: 30px;
        padding-left: 30px;
    }

    .main-title {
        font-size: 42px;
        line-height: 50px;
    }

}*/


@media screen and (max-width: 550px) {

    .main-section {
        padding-bottom: 300px;
    }

    .request-access-input-section {
        display: none;
    }

    .home-main-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin: auto;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 80px;
    }

    .home-request-access-section {
        display: none;
    }

    .main-home-image-col {
        display: none;
    }

    .title-col {
        margin-right: 30px;
        margin-left: 30px;
    }

    .main-title {
        font-size: 32px;
        color: #F2F2F7;
        line-height: 42px;
    }

}