
.link-new-account-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 20px;
}

.link-new-account-text {
    color: #2A0084;
    cursor: pointer;
}

.linking-account-modal-content {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 425px;
}

.account-linking-header {
    font-size: 20px;
    margin-bottom: 0px;
}

.account-linking-text {
    font-size: 16px;
}