
.sidenav-container {
    position: fixed;
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #1A0253;
    width: 190px;
    padding-left: 30px;
    padding-top: 60px;
    height: 100%;
    overflow-x: hidden;
}

.sidenav-logo-container {
    margin-bottom: 80px;
    cursor: pointer;
}

.side-nav-logo {
    width: 60px;
}

.side-nav-row {
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
}

.side-nav-row-bottom {
    position: fixed;
    bottom: 50px;
}

.side-nav-link {
    font-family: Inter;
    color: #AEAEAE;
    font-size: 18px;
}

.side-nav-link-focused {
    color: white;
}

.side-nav-icon {
    color: white;
    margin-right: 10px;
    padding: 0px;
    display: flex;
    align-items: center;
}
