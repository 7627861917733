
.clickable-row-container {
    border-bottom: 1px solid #E5E5E5;
    width: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 5px 0px;
}

.clickable-row-icon-col {
    flex: 1;
}

.clickable-row-text-col {
    flex: 6;
}

.clickable-row-arrow-col {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.clickable-row-text {
    font-size: 16px;
}