@import url("https://fonts.googleapis.com/css?family=Inter:400,300,700,500,600");

.main-button-container {
    display: flex;
}

.button {
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    margin: auto;
    background-color: #2A0084;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.button-inactive {
    background-color: rgba(35, 6, 103, 0.33);
}
