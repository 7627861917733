@import url("https://fonts.googleapis.com/css?family=Inter:300,700,600");

/* don't display this for larger screen sizes */
.mobile-request-access-section {
    display: none;
}

@media screen and (max-width: 550px) {

    .mobile-request-access-section {
        background-color: #F4F3F0;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom: 100px;
    }

    .mobile-request-access-container {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: auto;
        padding-right: 0px;
        padding-top: 20px;
    }

    .mobile-request-access-inputs {
        width: 85%;
        margin-top: 100px;
    }

    .mobile-phone-image-col {
        align-self: flex-start;
        margin-top: -150px;
        margin-left: 15%;
    }

    .mobile-dashboard-card-image {
        width: 450px;
        box-shadow: 0px 8px 8px 2px rgba(0,0,0,0.25);
    }

    .mobile-request-access-button {
        font-family: "Inter";
        font-weight: 600;
        font-size: 14px;
        width: 100%;
        margin: auto;
        margin-top: 10px;
        background-color: #2A0084;
        color: #F2F2F7;
        padding-top: 15px;
        padding-bottom: 15px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
    }

}