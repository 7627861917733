@import url("https://fonts.googleapis.com/css?family=Inter:300,700,600");

.disclaimers-section {
    background-color: #f4f3f0;
    width: 100%;
    display: flex;
}

.disclaimers-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-top: 80px;
    padding-bottom: 80px;
    width: 100%;
    max-width: 1100px;
    padding-left: 50px;
    padding-right: 50px;
}

.disclaimer-pdf-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 50px;
}

.disclaimer-pdf-col {
    display: flex;
}

.disclaimer-pdf-link {
    font-family: "Inter";
    color: #8f8f8f;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
}

.disclaimer-row {
    display: flex;
    flex: 1;
    margin-top: 15px;
}

.disclaimer-text {
    font-family: "Inter";
    color: #8f8f8f;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 0px;
}


@media screen and (max-width: 768px) {

    .disclaimers-container {
        padding-left: 25px;
        padding-right: 25px;
    }

}