.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
}

.modal-dummy-col {
    flex: 1;
}

.modal-main-col {
    flex: 12;
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
}

.modal-close-x {
    font-family: "Inter";
    font-size: 28px;
    margin-top: 0px;
}