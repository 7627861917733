@import url("https://fonts.googleapis.com/css?family=Inter:300,700,600");

.runway-section {
    background-color: #F4F3F0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 180px;
}

.runway-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 100px;
    padding-right: 100px;
    max-width: 1200px;
}

.runway-title-col {
    flex: 1;
    padding-right: 100px;
}

.runway-title {
    font-family: "Inter";
    font-weight: 600;
    font-size: 32px;
    color: #383838;
    line-height: 40px;
}

.runway-title-sub-text {
    font-family: "Inter";
    font-weight: 300;
    font-size: 16px;
    color: #383838;
    margin-top: 40px;
    line-height: 22px;
}

.runway-slider-section {
    flex: 1;
    margin-left: 25px;
}

.slider-container {
    margin-top: 80px;
}

.slider-dollar-value {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: #383838;
    margin-bottom: 20px;
}

.dollar-return-container {
    display: flex;
    flex-direction: row;
    margin-top: 100px;
    align-items: flex-end;
    padding-bottom: 20px;
}

.dollar-return-value {
    font-family: "Inter";
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    color: #383838;
    margin-bottom: 15px;
}

.month-text {
    font-family: "Inter";
    font-weight: 300;
    font-size: 24px;
    color: #383838;
    margin-left: 20px;
    padding-bottom: 0px;
}

.interest-income-text {
    font-family: "Inter";
    font-weight: 300;
    font-size: 24px;
    color: #383838;
    margin-left: 5px;
    padding-bottom: 0px;
}


@media screen and (max-width: 1024px) {

    .runway-title-col {
        padding-right: 90px;
    }

    .dollar-return-value {
        font-family: "Inter";
        font-weight: 600;
        font-size: 42px;
        line-height: 52px;
        color: #383838;
    }

    .month-text {
        font-size: 20px;
    }

    .interest-income-text {
        font-size: 20px;
    }

    .slider-container {
        margin-top: 80px;
    }

}

@media screen and (max-width: 900px) {

    .runway-container {
        padding-left: 30px;
        padding-right: 30px;
    }

    .runway-title-col {
        padding-left: 0px;
        padding-right: 30px;
    }

    .dollar-return-container {
        width: 425px;
    }

}


@media screen and (max-width: 820px) {

    .runway-section {
        padding-top: 140px;
    }

    .runway-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .runway-title-col {
        padding-left: 30px;
        padding-right: 30px;
    }

}


@media screen and (max-width: 550px) {

    .runway-section {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 140px;
    }

    .runway-container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .runway-title-col {
        padding-left: 0px;
        padding-right: 0px;
    }

    .runway-title {
        font-size: 24px;
        line-height: 32px;
    }

    .dollar-return-container {
        width: 100%;
    }

    .slider-container {
        margin-top: 80px;
    }

    .interest-income-text {
        display: none;
    }

}