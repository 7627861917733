.main-header-logo {
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
}

.header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 40px;
    padding-left: 7.5%;
}

.header-col {
    flex: 1;
}

.header-col-right {
    /* align the text to the right side of the screen */
    display: flex;
    justify-content: flex-end;
    padding-right: 60px;
    flex-direction: row;
    flex: 3;
}

.header-button-container {
    display: block;
    margin-left: 80px;
}

.sign-up-container {
    padding: 0px 50px;
    background-color: white;
    border-radius: 10px;
    margin-left: 50px;
    cursor: pointer;
}

.header-text {
    /* We want white text */
    font-size: 16px;
    color: white;
    font-family: "Inter";
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.sign-up-text {
    color: #2A0084;
}

@media screen and (max-width: 1200px) {

    

}

@media screen and (max-width: 960px) {

    .header-button-container {
        display: block;
        margin-left: 60px;
    }
    
    .sign-up-container {
        padding: 0px 45px;
        background-color: white;
        border-radius: 10px;
        margin-left: 45px;
    }

    .header-col-right {
        padding-right: 40px;
    }

}


@media screen and (max-width: 550px) {

    .main-header-logo {
        cursor: pointer;
        padding-top: 0px;
        padding-left: 30px;
    }

    .header-button-container {
        display: none;
    }

    .header-row {
        padding-left: 0px;
    }

    .header-col-right {
        padding-right: 30px;
    }

}