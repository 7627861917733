
.transfers-container {
    padding-top: 15px;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
}

.transfers-header {
    font-size: 20px;
}

.transfers-card-view-all-text {
    /* dark blue clickable text */
    font-size: 16px;
    color: #2A0084;
    cursor: pointer;
    margin-top: 30px;
}