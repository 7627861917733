
.request-demo-container {
    /* We want the text to be centered */
    display: block;
    width: 500px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 35px;
}

.request-demo-header {
    text-align: center;
    margin-bottom: 35px;
}

.request-demo-header-text {
    font-size: 20px;
    margin-top: 10px;
}

.request-demo-button {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    margin: auto;
    margin-top: 30px;
    background-color: #2A0084;
    color: white;
    padding: 15px 25px;
    margin-left: 0px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    width: 100%;
}

.request-demo-button-inactive {
    background-color: rgba(35, 6, 103, 0.33);
    cursor: default;
}

@media screen and (max-width: 550px) {

    .request-demo-container {
        /* We want the text to be centered */
        display: block;
        width: 90%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 25px;
    }

}