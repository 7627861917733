@import url("https://fonts.googleapis.com/css?family=Inter:400,300,700,500,600");

.main-container {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
}

.logo-container {
    padding-left: 75px;
    display: flex;
    justify-content: flex-start;
}

.logo-container-clickable {
    cursor: pointer;
}

.main-content {
    margin: auto;
    flex: 1;
    width: 575px;
    margin-top: 100px;
}

.button-container {
    flex-direction: column;
    margin-top: 80px;
    align-items: center;
    justify-content: center;
    padding-left: 18%;
    padding-right: 18%;
}

.back-button-container {
    flex-direction: row;
    align-items: center;
    text-align: center;
}

.back-button {
    border: none;
    background-color: white;
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    margin-top: 10px;
    color: #2A0084;
    cursor: pointer;
}

.back-button-hidden {
    display: none;
}

.logo {
    height: 35px;
}

/* iPad styling */
@media (max-width: 1024px) {

    .main-content {
        width: 500px;
    }

    .logo-container {
        padding-left: 50px;
        display: flex;
        justify-content: flex-start;
    }

}

/* iPad portrait styling */
@media (max-width: 780px) {

    .main-content {
        width: 75%;
    }

    .logo-container {
        padding-left: 12.5%;
        display: flex;
        justify-content: flex-start;
    }

}

/* iPhone styling */
@media (max-width: 550px) {

    .main-content {
        width: 90%;
    }

    .logo-container {
        padding-left: 5%;
        display: flex;
        justify-content: flex-start;
    }

    .logo {
        height: 30px;
    }

}
