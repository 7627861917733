.beneficial-owner-row {
    display: flex;
    align-items: center;
}

.beneficial-owner-name {
    font-size: 18px;
}

.beneficial-owner-ownership {
    font-size: 18px;
    margin-left: 15px;
}

.beneficial-owner-edit-button {
    margin-left: 30px;
    border: none;
    background-color: white;
    cursor: pointer;
}