.sub-header {
    margin-bottom: 50px;
}

.account-creation-header {
    font-size: 28px;
    margin-bottom: 0px;
}

.account-creation-input-row {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.first-name-container {
    margin-right: 5px;
    width: 100%;
}

.last-name-container {
    margin-left: 5px;
    width: 100%;
}

.link-text {
    text-decoration: none;
    color: blue;
}