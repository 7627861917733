
.home-container {
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    padding-bottom: 100px;
}

.main-header {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 25px;
}

.home-card-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.home-card-account-col {
    flex: 1;
}

.home-card-allocation-col {
    flex: 5;
}

.home-card-transfers-col {
    flex: 6;
}
