.benficial-owners-section {
    margin-top: 0px;
}

.beneficial-owners-header {
    font-size: 22px;
}

.beneficial-owner-add-row {
    display: flex;
    align-items: center;
}

.beneficial-owner-add-button {
    text-align: center;
    border: none;
    background-color: white;
    margin-right: 20px;
    margin-left: 0px;
}

.plus-icon {
    height: 25px;
    cursor: pointer;
    margin-left: 0px;
}