
.deposit-withdrawal-card-container {
    padding: 20px 30px;
}

.deposit-withdrawal-card-row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #E8E8E8;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
}

.deposit-withdrawal-card-title-col {
    flex: 1;
}

.deposit-withdrawal-card-info-col {
    display: flex;
    flex: 4;
    flex-direction: row;
    align-items: center;
}

.deposit-withdrawal-card-button-container {
    display: flex;
    margin-top: 85px;
    margin-bottom: 15px;
    width: 140px;
}

.deposit-withdrawal-card-button-col {
    flex: 1;
}
