
.form-container {
    flex-direction: column;
    width: 100%;
}

.label {
    display: flex;
    font-size: 14px;
    margin-bottom: 8px;
}

.text-input-container {
    display: flex;
    margin-top: 0px;
}

.text-input {
    border-width: 0px;
    display: flex;
    width: 100%;
    font-size: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px;
    border: 1px solid #D8D8D8;
    border-radius: 8px;
    background-color: rgba(0,0,0,0);
    font-family: "Inter";
    font-weight: 400;
    color: #383838 !important;
}

.text-input::placeholder {
    color: #C8C8C8;
}

.text-input:focus {
    outline: none;
    border: 1px solid #1A0253;
    border-radius: 8px;
}

.text-input-hidden:focus {
    outline: none;
}

.text-input-light {
    /* Chrome doesn't recognize this class without important */
    color: white !important;
}

.text-input-light:focus {
    outline: none;
    border: 1px solid white;
    border-radius: 8px;
}
