
.statement-year-container {
    width: 200px;
}

.statement-year-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.statement-year {
    font-size: 16px;
}

.statement-month-row {
    margin-top: 15px;
    margin-left: 20px;
}

.statement-month {
    color: #2A0084;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
}