.upload-icon-button {
    border: none;
    background-color: white;
}

.upload-icon {
    cursor: pointer;
    height: 40px;
}

.id-upload-row {
    display: flex;
    margin-top: 30px;
}

.id-upload-icon-box {
    margin-right: 40px;
}