.password-requirement-container {
    padding-top: 10px;
    padding-bottom: 0px;
}

.password-requirement-text {
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 13px !important;
}

.password-requirement-text-satisfied {
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 13px !important;
    color: #00B026;
    font-weight: 500;
}

.password-input-container {
    border-width: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-size: 14px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 15px;
    border-radius: 8px;
    border: 1px solid #D8D8D8;
    border-radius: 8px;
    background-color: rgba(0,0,0,0);
    font-family: "Inter";
    font-weight: 400;
    color: #383838 !important;
}

.password-input-container:focus-within {
    border: 1px solid #1A0253;
}

.show-password-button {
    margin-left: 20px;
    cursor: pointer;
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
}