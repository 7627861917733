
.portfolio-allocation-container {
    padding-top: 15px;
    padding-bottom: 45px;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
}

.portfolio-allocation-header {
    font-size: 20px;
}
