
.account-overview-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding-top: 10px;
    padding-bottom: 10px;
}

.account-overview-detail {
    flex: 1;
    padding-left: 40px;
    padding-right: 20px;
    display: flex;
    flex-direction: column
}

.account-overview-detail-row {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.account-overview-detail-row-lower {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: flex-end;
    padding-top: 0px;
    padding-bottom: 10px;
}

.account-overview-title {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #383838;
}

.account-overview-return-col {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.return-icon {
    margin-left: 30px;
}

.account-overview-percent-return {
    font-size: 20px;
    margin-bottom: 0px;
    margin-left: 15px;
}
