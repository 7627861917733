
.account-container {
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    padding-bottom: 100px;
}

.main-header {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 25px;
}

.account-section-row {
    border-bottom: 1px solid #E5E5E5;
    width: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 5px 0px;
}

.account-section-icon-col {
    flex: 1;
}

.account-section-text-col {
    flex: 6;
}

.account-section-arrow-col {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.account-section-text {
    font-size: 16px;
}

.account-card-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.account-card-col {
    flex: 5;
}
