
.etf-info-card-container {
    padding-top: 15px;
    padding-bottom: 45px;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
}

.etf-info-card-header {
    font-size: 20px;
}

.etf-yield-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0px;
}

.symbol-col {
    flex: 5;
}

.yield-col {
    flex: 2;
    text-align: right;
}

.percentage-col {
    /* align the text to the right */
    flex: 2;
    text-align: right;
}

.etf-yield-row-symbol {
    font-size: 16px;
    margin-bottom: 0px;
}

.etf-yield-row-symbol img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.etf-yield-row-description {
    font-size: 16px;
}

.etf-yield-row-yield {
    font-size: 16px;
    margin-bottom: 0px;
}

.etf-yield-row-percentage {
    font-size: 16px;
    margin-bottom: 0px;
    color: #2a0084;
}